import React, { useState } from "react";

// Files Import
import { content } from "../../../data";
import Copyrights from "../../Copyrights/Copyrights";
import { Link, NavLink, useLocation } from "react-router-dom";
import StyledH5Heading from "../../../common/components/styledH5Heading/StyledH5Heading";

const MobileHeader = () => {
  const location = useLocation();
  const [navShow, setNavShow] = useState(false);

  const toggleNav = () => {
    setNavShow(!navShow);
    document.body.style.overflowY = navShow ? "auto" : "hidden";
  };

  const closeNav = () => {
    setNavShow(false);
    document.body.style.overflowY = "auto";
  };
  return (
    <div className="block lg:hidden">
      {/* Hamburger Button */}
      <button
        type="button"
        className="flex items-center justify-center absolute bottom-[23%] right-[12%] sm:right-[10%] md:right-[10%]"
        aria-label="Toggle Menu"
        onClick={toggleNav}
      >
        <img
          src="/assets/Images/hamburger.svg"
          alt="Menu"
          className="w-6 h-6"
        />
      </button>
      <div
        className={`bg-river-blue w-screen h-full fixed top-0 left-0 flex py-4 ${
          navShow ? "translate-x-[0%]" : "-translate-x-[110%]"
        }`}
        style={{ transition: "all 0.5s  cubic-bezier(.68,-0.55,.27,1.55)" }}
      >
        {/* Header */}
        <div className="flex flex-col items-center justify-between w-11/12 mx-auto">
          <div className="w-full space-y-4">
            <div className="flex items-center justify-between w-full">
              <Link to="/" className="text-white">
                <img
                  className="w-[80%]"
                  src="/assets/Images/logo.svg"
                  alt="Logo"
                />
              </Link>

              <button
                type="button"
                className="z-10 w-fit "
                aria-label="Toggle Menu"
                onClick={toggleNav}
              >
                <img
                  src="/assets/Images/cross.svg"
                  alt="Cross Icon"
                  className="w-30 h-30"
                />
              </button>
            </div>
            <hr className="h-[2px] bg-white w-full" />
          </div>

          {/* NavLinks */}
          <div>
            <div className="flex flex-col items-center justify-center gap-3 text-white md:gap-6 w-fit">
              {content.Footer.map((item, index) => (
                <NavLink
                  to={item.link}
                  className={`${
                    location.pathname === item.link
                      ? "text-sky-blue"
                      : "text-white"
                  }  hover:scale-x-110 transform transition`}
                  key={index}
                  onClick={closeNav}
                >
                  <StyledH5Heading fontColor={"!font-bold hover:text-sky-blue"}>
                    {item.title}
                  </StyledH5Heading>
                </NavLink>
              ))}
            </div>
          </div>

          {/* Footer */}
          <div>
            <div className="flex flex-col items-center justify-center gap-2 text-white">
              {/* Social Links */}
              <div className="flex -space-x-1 text-white lg:flex">
                {content.socialLinks.map((item, index) => (
                  <Link
                    className="hover:border-blue border border-transparent rounded-full scale-75 hover:scale-[0.8] transform transition duration-300"
                    key={index}
                    to={item.link}
                    target="_blank"
                  >
                    {item?.icon}
                  </Link>
                ))}
              </div>
              <div>
                <Copyrights />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
