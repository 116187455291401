import React, { useLayoutEffect, useRef } from "react";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

/* Files Import */
import Button from "../../../components/Button/Button";
import StyledXlText from "../../../common/components/styledXlText/StyledXlText";
import StyledH1Heading from "../../../common/components/styledH1Heading/StyledH1Heading";
import StyledH6Heading from "../../../common/components/styledH6Heading/StyledH6Heading";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const HeroSection = () => {

  const leftContentTag = useRef();
  const leftContentHeading = useRef();
  const leftContentText = useRef();
  const leftContentSocials = useRef();

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(leftContentTag.current, {
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.2
      gsap.from(leftContentHeading.current, {
        duration: 2,
        delay: 0.25,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.3
      gsap.from(leftContentText.current, {
        delay: 0.5,
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

      //Animation No.4
      gsap.from(leftContentSocials.current, {
        delay: 0.75,
        duration: 2,
        opacity: 0,
        ease: "ease-in-out",
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  const scrollToSection = (event, sectionId) => {
    event.preventDefault();
    const section = document.querySelector(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className="relative z-20 w-full hero-background">
      <div className="w-[85%] mx-auto">
        {/* Heading */}
        <div className="flex flex-col items-start justify-start gap-5 md:w-4/5 lg:w-4/6 xl:w-5/12 py-36 md:py-44">
          <div ref={leftContentTag} className="flex px-4 py-2 text-white rounded-lg bg-river-blue bg-opacity-60 glassEffect">
            <StyledH6Heading children={"Web3-Powered Platform"} />
          </div>

          <div className="flex flex-col gap-2">
            <div ref={leftContentHeading}>
              <StyledH1Heading fontColor="text-white leading-[110%]">
                Secure <br />
                <span className="text-sky-blue flex-nowrap">
                  Empower Thrive
                </span>
              </StyledH1Heading>
            </div>
            <div ref={leftContentText}>
              <StyledXlText
                fontColor={"text-light-gray"}
                children={
                  "With BlockMed Pro, experience unparalleled safety, unleash your health potential, and flourish in a connected healthcare ecosystem!"
                }
              />
            </div>
          </div>
          <div ref={leftContentSocials} onClick={(e) => scrollToSection(e, "#roadmap")}>
            <Button content={"View Roadmap"} />
          </div>
        </div>
      </div>
      {/* Currency Icons Images */}
      <div>
        <img
          src="/assets/Images/eth-ball.png"
          alt="ETH"
          className="absolute top-[15%] right-[5%] sm:top-[21%] sm:right-[20%] lg:top-[21%] lg:right-[20%] md:top-[15%] md:right-[15%] max-w-[25%] sm:w-fit transition duration-500 hover:scale-110"
        />
        <img
          src="/assets/Images/bnb-ball.png"
          alt="BNB"
          className="absolute bottom-[16%] right-[6%] sm:top-[66%] sm:right-[12%] lg:top-[66%] lg:right-[12%] max-w-[25%] md:top-[73%] md:right-[8%] sm:w-fit transition duration-500 hover:scale-110"
        />
        <img
          src="/assets/Images/crypto.png"
          alt="Crypto Coin"
          className="absolute top-[83%] right-[58%] sm:top-[80%] sm:right-[60%] lg:top-[65%] lg:right-[39%] max-w-[25%] md:top-[73%] md:right-[50%] sm:w-fit transition duration-500 hover:scale-110"
        />
      </div>
    </div>
  );
};

export default HeroSection;
