import React, { useState } from "react";

// Files Import
import Button from "../Button/Button";

const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
const validEmail = (email) => {
  return emailRegex.test(email);
};

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const handleInputChange = (
    e,
    setState,
    setErrorState,
    validationFunc = null
  ) => {
    const value = e.target.value;
    setState(value);
    setErrorState(
      value.length < 3 || (validationFunc && !validationFunc(value))
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Validation logic
    if (
      !validEmail(email) ||
      firstName.length < 3 ||
      lastName.length < 3 ||
      subject.length < 3 ||
      message.length < 3
    ) {
      // Handle validation errors
      console.log("Form validation failed");
      return;
    }

    console.log("Form submitted:", {
      firstName,
      lastName,
      email,
      subject,
      message,
    });
  };

  return (
    <form className="mx-auto space-y-5" onSubmit={handleSubmit}>
      <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
        <div>
          <label
            htmlFor="first-name"
            className="block text-sm font-semibold leading-6 text-white"
          >
            First name <span className="text-red-500">*</span>
          </label>
          <div className="mt-1">
            <input
              required
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              placeholder="John Deo"
              value={firstName}
              onChange={(e) =>
                handleInputChange(e, setFirstName, setFirstNameError)
              }
              className={`block w-full rounded-md px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6 outline-none bg-transparent border ${
                firstNameError ? "border-red-500" : "border-white"
              } text-white`}
            />
            {firstNameError && (
              <p className="text-red-500 text-sm">Minimum characters: 3</p>
            )}
          </div>
        </div>
        <div>
          <label
            htmlFor="last-name"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Last name <span className="text-red-500">*</span>
          </label>
          <div className="mt-1">
            <input
              required
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              placeholder="John Deo"
              value={lastName}
              onChange={(e) =>
                handleInputChange(e, setLastName, setLastNameError)
              }
              className={`block w-full rounded-md px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6 outline-none bg-transparent border ${
                lastNameError ? "border-red-500" : "border-white"
              } text-white`}
            />
            {lastNameError && (
              <p className="text-red-500 text-sm">Minimum characters: 3</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="email"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Email Address <span className="text-red-500">*</span>
          </label>
          <div className="mt-1">
            <input
              required
              type="email"
              name="email"
              id="email"
              autoComplete="email"
              placeholder="johndoe@gmail.com"
              value={email}
              onChange={(e) =>
                handleInputChange(e, setEmail, setEmailError, validEmail)
              }
              className={`block w-full rounded-md px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6 outline-none bg-transparent border ${
                emailError ? "border-red-500" : "border-white"
              } text-white`}
            />
            {emailError && (
              <p className="text-red-500 text-sm">Invalid email address</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="subject"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Subject <span className="text-red-500">*</span>
          </label>
          <div className="mt-1">
            <input
              required
              type="text"
              name="subject"
              id="subject"
              placeholder="Subject"
              value={subject}
              onChange={(e) =>
                handleInputChange(e, setSubject, setSubjectError)
              }
              className={`block w-full rounded-md px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6 outline-none bg-transparent border ${
                subjectError ? "border-red-500" : "border-white"
              } text-white`}
            />
            {subjectError && (
              <p className="text-red-500 text-sm">Minimum characters: 3</p>
            )}
          </div>
        </div>
        <div className="sm:col-span-2">
          <label
            htmlFor="message"
            className="block text-sm font-semibold leading-6 text-white"
          >
            Write your issue here <span className="text-red-500">*</span>
          </label>
          <div className="mt-1">
            <textarea
              name="message"
              id="message"
              rows="4"
              placeholder="Write here"
              value={message}
              onChange={(e) =>
                handleInputChange(e, setMessage, setMessageError)
              }
              className={`block w-full rounded-md px-3.5 py-2 shadow-sm sm:text-sm sm:leading-6 outline-none bg-transparent border ${
                messageError ? "border-red-500" : "border-white"
              } text-white min-h-48`}
            ></textarea>
            {messageError && (
              <p className="text-red-500 text-sm">Minimum characters: 3</p>
            )}
          </div>
        </div>
      </div>
      <div className="flex justify-center">
        <Button href={"/"} content={"Send"} />
      </div>
    </form>
  );
};

export default ContactForm;
