import React, { useLayoutEffect, useRef } from "react";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Files Import
import StyledMdText from "../../../common/components/styledMdText/StyledMdText";
import StyledH3Heading from "../../../common/components/styledH3Heading/StyledH3Heading";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const MotiveSection = () => {

  const bgBanner = useRef()
  const heading = useRef()
  const text = useRef()

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(bgBanner.current, {
        duration: 1,
        opacity: 1,
        x: "-100%", // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: bgBanner.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=20%", // Adjust the start position as needed
          // markers: true,
        },
      });

      //Animation No.2
      gsap.from(heading.current, {
        duration: 1,
        delay: 1,
        opacity: 0,
        x: -50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: bgBanner.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=20%", // Adjust the start position as needed
          // markers: true,
        },
      });

      //Animation No.3
      const textLines = text.current?.querySelectorAll(".line span");
      gsap.from(textLines, {
        duration: 1,
        delay: 2,
        opacity: 0,
        y: -200,
        ease: "power2.out",
        skewY: 10,
        stagger: {
          amount: 1,
        },
        scrollTrigger: {
          trigger: bgBanner.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=20%", // Adjust the start position as needed
          // markers: true,
        },
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <div className="relative w-full h-full md:min-h-[500px]">
      <div ref={bgBanner}
        className="motive-bg-gradient md:w-1/2 z-10 flex items-center justify-center px-5 min-h-[394px]
        md:absolute top-0 left-0 right-0 bottom-0"
      >
        {/* Heading */}
        <div className="flex flex-col gap-3 py-10 lg:w-3/4">
          <div className="flex flex-col gap-3" ref={heading}>
            <div className="flex items-center gap-3">
              <hr className="h-px bg-white border-0 rounded-md hr-gradient2 w-44 opacity-70" />
            </div>
            <div>
              <StyledH3Heading
                fontColor={"text-white "}
                children={"Our Vision"}
              />
            </div>
          </div>
          <div ref={text}>
            <StyledMdText
              fontColor={"text-white line"}>
              <span>In the near future, BlockMed Pro envisions a transformative shift in</span><span> healthcare, where patients are unequivocally at the helm of their health</span><span> journey, empowered by unparalleled access and control over their medical</span><span> records through the robust, transparent, and secure infrastructure provided</span><span> by blockchain technology. As the gold standard for electronic health</span><span> records, BlockMed Pro aims to bridge the gap between healthcare</span><span> providers and patients, fostering a seamless, integrated ecosystem that</span><span> prioritizes privacy, security, and patient autonomy. Through this innovation,</span><span> we are committed to not only enhancing the quality of care and patient</span><span> outcomes but also setting a new benchmark for trust and efficiency in</span><span> healthcare management worldwide.</span>
            </StyledMdText>
          </div>
        </div>
      </div>
      {/* Video */}
      <video
        className="w-full min-h-[394px] md:h-[499px] object-cover"
        loop
        autoPlay
        muted
        playsInline
        preload="auto"
        poster="/assets/Videos/blockchain-video.mp4"
        onLoadedMetadata={(e) => {
          e.target.muted = true;
          e.target.play();
        }}
      >
        <source src="/assets/Videos/blockchain-video.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default MotiveSection;
