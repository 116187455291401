export const content = {
  header: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About",
      link: "/about-us",
    },
    {
      title: "FAQ’s",
      link: "/faq",
    },
    {
      title: "Contact",
      link: "/contact-us",
    },
  ],
  Footer: [
    {
      title: "Home",
      link: "/",
    },
    {
      title: "About",
      link: "/about-us",
    },
    {
      title: "Faq",
      link: "/faq",
    },
    {
      title: "Contact Us",
      link: "/contact-us",
    },
  ],
  accordionData: [
    {
      count: "01",
      title: "Decentralised Healthcare Ecosystem",
      content:
        "At the heart of BlockMed Pro is blockchain technology, a distributed ledger system that ensures data integrity, security, and transparency. By incorporating blockchain, BlockMed Pro enables a decentralised approach to storing health records, where each transaction (be it an update or access to medical records) is encrypted and recorded across multiple nodes through the use of Smart Contracts. These act as self-executing contracts with the terms of the agreement between patient and healthcare entities directly written into lines of code. This method not only enhances security by making data tampering practically impossible but also ensures that patient data remains private, secure from unauthorised access guaranteeing every access and use of medical data to be in strict compliance with regulatory standards. All of this allows BlockMed Pro to set a new benchmark in safeguarding patient data and adhering to the highest standards of compliance in the digital age.",
    },
    {
      count: "02",
      title: "Monetisation of Data & AI Analytics",
      content:
        "Utilising high levels of encryption and pseudonymisation techniques, patients will have their data converted to a format that allows them to directly sell to Pharmaceutical companies. This unprecedented level of control of their health record empowers patients to directly impact and gain from medical research. Pharmaceutical companies will massively benefit from having access to granular, longitudinal patient data and BlockMed Pro will provide this individual patient data across demographics and clinical histories, ideal for tracking treatment pathways, health outcomes, and patient responses over time. The integration of high-end AI tools will help support in-depth analytics, cohort selection, predictive modelling, and visualisation capabilities.",
    },
    {
      count: "03",
      title: "Efficient 360 Degrees Integration",
      content:
        "BlockMed Pro's platform epitomises the future of integrated healthcare. It has been meticulously designed to place the patient at the core of the health care experience. This revolutionary approach not only empowers patients with complete control over their medical data but also facilitates a seamless, consent-based sharing mechanism across the healthcare spectrum, including clinics, pharmacies, and pharmaceutical companies. By enabling these key stakeholders to access electronic health records (EHR) data effortlessly — with explicit patient consent — BlockMed Pro ensures a coordinated, comprehensive, and efficient healthcare delivery process. This interoperability fosters a collaborative healthcare environment where decisions are informed by a holistic view of the patient's health history, enhancing treatment outcomes and elevating patient care to unprecedented levels.",
    },
  ],
  faqAccordionData: [
    {
      title: "How can I access my medical records through BlockMed Pro?",
      content:
        "You can access your medical records securely through the Web3-based BlockMed Pro platform using your unique login credentials that includes Multi-Factor Authentication. Our user-friendly interface ensures easy navigation for viewing, managing, and sharing your health information.",
    },
    {
      title: "Who can access my health data without my permission?",
      content:
        "Absolutely no one. BlockMed Pro prioritises your privacy; only you have the control to grant or revoke access to your medical records to healthcare providers, pharmacies, and other entities.",
    },
    {
      title: "How can I share my medical history with new healthcare providers within the BlockMed Pronetwork?",
      content:
        "Within the BlockMed Pro ecosystem, sharing your medical history with new or existing healthcare providers is streamlined and secure. With your consent, providers can easily access your records for a comprehensive view of your health history, facilitating better-coordinated care and informed medical decisions.",
    },
    {
      title: "How can I benefit financially from using BlockMed Pro?",
      content:
        "BlockMed Pro offers a unique feature where you can choose to share your anonymised health data with pharmaceutical companies for research purposes. In return for contributing to medical research, you will receive remuneration, providing a financial incentive while advancing healthcare innovation.",
    },
  ],
  faqPageAccordion: [
    {
      title: "How can I access my medical records through BlockMed Pro?",
      content:
        "You can access your medical records securely through the Web3-based BlockMed Pro platform using your unique login credentials that includes Multi-Factor Authentication. Our user-friendly interface ensures easy navigation for viewing, managing, and sharing your health information.",
    },
    {
      title: "Who can access my health data without my permission?",
      content:
        "Absolutely no one. BlockMed Pro prioritises your privacy; only you have the control to grant or revoke access to your medical records to healthcare providers, pharmacies, and other entities.",
    },
    {
      title: "How can I share my medical history with new healthcare providers within the BlockMed Pronetwork?",
      content:
        "Within the BlockMed Pro ecosystem, sharing your medical history with new or existing healthcare providers is streamlined and secure. With your consent, providers can easily access your records for a comprehensive view of your health history, facilitating better-coordinated care and informed medical decisions.",
    },
    {
      title: "How can I benefit financially from using BlockMed Pro?",
      content:
        "BlockMed Pro offers a unique feature where you can choose to share your anonymised health data with pharmaceutical companies for research purposes. In return for contributing to medical research, you will receive remuneration, providing a financial incentive while advancing healthcare innovation.",
    },
    {
      title: "What payment methods are accepted on BlockMed Pro?",
      content:
        "BlockMed Pro is designed to accommodate a wide range of payment methods for its services, including traditional currencies like USD and GBP , as well as cryptocurrencies, offering flexibility and convenience to you.",
    },
    {
      title: "How does BlockMed Pro serve as the gold standard for electronic health records?",
      content:
        "BlockMed Pro is not just another EHR platform; it's a comprehensive solution that all clinics and pharmacies utilise as the primary, all-encompassing EHR system, ensuring uniformity, security, and efficiency in managing patient records. With advanced security, seamless integration capabilities, and a patient-centred approach, it sets a new benchmark for quality and efficiency in healthcare management.",
    },
    {
      title: "How does BlockMed Pro ensure ease of use for clinicians?",
      content:
        "BlockMed Pro is designed by clinicians for clinicians, featuring customisable medical templates for various specialties, simplified prescribing processes, and multiple communication methods with patients, pharmacies, and other healthcare professionals. This user-friendly interface allows for efficient record-keeping and coordination of care, all within a secure and compliant platform.",
    },
    {
      title: "How can healthcare providers collaborate within the BlockMed Pro ecosystem?",
      content:
        "Within the BlockMed Pro ecosystem, healthcare providers will be able to seamlessly refer patients to one another, request investigations, and share patient information securely, all with patient consent. This interoperability fosters a collaborative environment, ensuring that every healthcare touchpoint is connected and efficient.",
    },
    {
      title: "How does BlockMed Pro cater to the needs of community pharmacies?",
      content:
        "Community pharmacies play a crucial role in patient care, and BlockMed Pro will seamlessly integrate pharmacy operations with our EHR system. Pharmacies will be able to access prescriptions, manage medication records, and communicate with prescribers directly, ensuring accurate and efficient dispensing of medications.",
    },
    {
      title: "What benefits does BlockMed Pro offer to pharmaceutical company users?",
      content:
        "Pharmaceutical companies on BlockMed Pro can access a wealth of pseudonymised patient data, with consent, for research and development purposes. This data is longitudinal in nature, granular down to patient level, and our platform has built in analytics to support business intelligence and R&D teams. This provides valuable insights into patient outcomes and medication efficacy, driving innovation while ensuring patient privacy and data security.",
    },
    {
      title: "How does blockchain technology benefit the BlockMed Pro platform?",
      content:
        "Blockchain technology offers a decentralised, secure, and transparent way to store and manage electronic health records. It enhances data security, patient privacy, and enables the immutable recording of transactions, ensuring data integrity and trust across the healthcare ecosystem. Once information is entered into the blockchain, it cannot be altered or deleted, ensuring the accuracy and reliability of medical data.",
    },
    {
      title: "Are Smart Contracts secure?",
      content:
        "Yes, Smart Contracts are highly secure. They automate and enforce the execution of agreements based on predefined rules, significantly reducing the risk of data breaches and unauthorised access, while ensuring compliance with healthcare regulations. BlockMed Pro employs advanced encryption as well as Smart Contract enabled security and strict access controls to protect patient data and ensure privacy. Only authorised individuals with explicit patient consent can access specific health records, in compliance with global privacy regulations.",
    },
    {
      title: "What are electronic health records (EHRs), and how does BlockMed Pro enhance their utility?",
      content:
        "Electronic Health Records (EHRs) are digital versions of patients' paper charts, offering a real-time, patient-centred record accessible to authorised users. BlockMed Pro enhances EHRs by integrating blockchain for secure, immutable record-keeping and ensuring that data is accessible across the healthcare continuum with patient consent.",
    },
  ],
  roadmap: [
    {
      imgURL: "/assets/Images/phase-1.svg",
      phase: "Phase 1, Q1 & Q2",
      timePeriod: "2024",
    },
    {
      imgURL: "/assets/Images/phase-2.svg",
      phase: "Phase 2, Q3",
      timePeriod: "2024",
    },
    {
      imgURL: "/assets/Images/phase-3.svg",
      phase: "Phase 3, Q4",
      timePeriod: "2024",
    },
    {
      imgURL: "/assets/Images/phase-1.svg",
      phase: "Phase 1, Q1",
      timePeriod: "2025",
    },
    {
      imgURL: "/assets/Images/phase-2.svg",
      phase: "Phase 2, Q2",
      timePeriod: "2025",
    },
    {
      imgURL: "/assets/Images/phase-3.svg",
      phase: "Phase 3, Q3",
      timePeriod: "2025",
    },
    {
      imgURL: "/assets/Images/phase-1.svg",
      phase: "Phase 4, Q4",
      timePeriod: "2025",
    },
    {
      imgURL: "/assets/Images/phase-2.svg",
      phase: "Phase 1, Q1",
      timePeriod: "2026",
    },
  ],
  roadmapContent: [
    {
      box: [
        {
          list: "Platform Design",
        },
        {
          list: "Development of Web3 dApp",
        },
      ],
    },
    {
      box: [
        {
          list: "Onboarding of clinics and pharmaceutical companies",
        },
      ],
    },
    {
      box: [
        {
          list: "Completion of Minimum Viable Product (MVP)",
        },
        {
          list: "Pre-seed round raising $500,000",
        },
      ],
    },
    {
      box: [
        {
          list: "MVP optimisation",
        },
        {
          list: "Onboarding of initial UK based patients and pharmaceutical companies to deploy monetisation options",
        },
        {
          list: "Completion of Super Admin Module (CRM & CMS)",
        },
        {
          list: "Seed round raising $4,500,000",
        },
      ],
    },
    {
      box: [
        {
          list: "Scale customer acquisition through GP partnerships",
        },
        {
          list: "Development of Clinic & Pharmacy Modules",
        },
      ],
    },
    {
      box: [
        {
          list: "Scaling marketing efforts",
        },
        {
          list: "Development of advanced patient engagement features (AI integration & personalised care)",
        },
        {
          list: "Design & development of mobile App",
        },
      ],
    },
    {
      box: [
        {
          list: "Completion of Clinics, Pharmacy Modules and advanced patient engagement features",
        },
        {
          list: "Broad market launch in other global markets",
        },
      ],
    },
    {
      box: [
        {
          list: "Completion & Launch of mobile App",
        },
      ],
    },
  ],
  Team: [
    {
      imgURL: "/assets/Images/ceo.jpeg",
      name: "Dr. Burhan Ahmed",
      profession: "CEO & Co-Founder",
      socialLink: "https://www.linkedin.com/in/mirza-burhan-ahmed-b1528086/",
    },
    {
      imgURL: "/assets/Images/employee2.png",
      name: "Sadia Akhtar",
      profession: "COO & Co-Founder",
      socialLink: "https://www.linkedin.com/in/sadia-a-88547172/",
    },
    {
      imgURL: "/assets/Images/cdo.png",
      name: "Bobbie Khan",
      profession: "CDO & Co-Founder",
      socialLink: "https://www.linkedin.com/in/bobbiekhan/",
    },
    {
      imgURL: "/assets/Images/lambat.png",
      name: "Mohammed Lambat",
      profession: "CFO & Co-Founder",
      socialLink: "https://www.linkedin.com/in/mohammed-ali-lambat-92526768",
    },
  ],
  socialLinks: [
    // twitter
    {
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="45"
          height="45"
          viewBox="0 0 39 39"
          fill="none"
        >
          <path
            d="M38.5 19.0995C38.5 29.6425 29.988 38.1991 19.5 38.1991C9.012 38.1991 0.5 29.6425 0.5 19.0995C0.5 8.55659 9.012 0 19.5 0C29.988 0 38.5 8.55659 38.5 19.0995Z"
            fill="#0F1C51"
          />
          <path
            d="M24.8885 12.0627H27.4953L21.8002 18.1585L28.5 26.4535H23.2541L19.1454 21.4227L14.444 26.4535H11.8357L17.9271 19.9334L11.5 12.0627H16.879L20.593 16.6612L24.8885 12.0627ZM23.9736 24.9923H25.418L16.0942 13.4472H14.5441L23.9736 24.9923Z"
            fill="white"
          />
        </svg>
      ),

      link: "https://x.com/BlockMedPro",
    },
    {
      icon: (
        <svg width="45" height="45" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M26.8692 13.2866C26.8692 20.6207 20.9478 26.5731 13.6519 26.5731C6.35593 26.5731 0.43457 20.6207 0.43457 13.2866C0.43457 5.95238 6.35593 0 13.6519 0C20.9478 0 26.8692 5.95238 26.8692 13.2866Z" fill="#0F1C51" />
          <path d="M8 15.9444V11.0556C8 10.2452 8.32192 9.46798 8.89495 8.89495C9.46798 8.32192 10.2452 8 11.0556 8H15.9444C16.7548 8 17.532 8.32192 18.105 8.89495C18.6781 9.46798 19 10.2452 19 11.0556V15.9444C19 16.7548 18.6781 17.532 18.105 18.105C17.532 18.6781 16.7548 19 15.9444 19H11.0556C10.2452 19 9.46798 18.6781 8.89495 18.105C8.32192 17.532 8 16.7548 8 15.9444Z" fill="white" />
          <path d="M13.5 15.9445C14.1483 15.9445 14.77 15.687 15.2285 15.2285C15.6869 14.7701 15.9444 14.1484 15.9444 13.5C15.9444 12.8517 15.6869 12.23 15.2285 11.7716C14.77 11.3131 14.1483 11.0556 13.5 11.0556C12.8517 11.0556 12.2299 11.3131 11.7715 11.7716C11.3131 12.23 11.0555 12.8517 11.0555 13.5C11.0555 14.1484 11.3131 14.7701 11.7715 15.2285C12.2299 15.687 12.8517 15.9445 13.5 15.9445V15.9445Z" stroke="#0F1C51" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
          <path d="M16.8612 10.1444L16.8668 10.1382" stroke="#0F1C51" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),

      link: "https://www.instagram.com/blockmedpro",
    },
    // // Discord
    // {
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="45"
    //       height="45"
    //       viewBox="0 0 39 39"
    //       fill="none"
    //     >
    //       <path
    //         d="M38.5 19.0995C38.5 29.6425 29.988 38.1991 19.5 38.1991C9.012 38.1991 0.5 29.6425 0.5 19.0995C0.5 8.55659 9.012 0 19.5 0C29.988 0 38.5 8.55659 38.5 19.0995Z"
    //         fill="#0F1C51"
    //       />
    //       <path
    //         d="M27.0322 13.9456C24.5814 12.0089 22.2336 12.0631 22.2336 12.0631L21.9936 12.3526C24.9071 13.2759 26.261 14.6334 26.261 14.6334C22.0928 12.2094 16.7832 12.2268 12.4476 14.6334C12.4476 14.6334 13.8529 13.2034 16.9378 12.2801L16.7664 12.0629C16.7664 12.0629 14.4356 12.0086 11.9678 13.9454C11.9678 13.9454 9.5 18.6337 9.5 24.408C9.5 24.408 10.9396 27.0146 14.7272 27.1413C14.7272 27.1413 15.3613 26.3448 15.8754 25.657C13.6988 24.9693 12.8762 23.5393 12.8762 23.5393C14.2436 24.4429 15.6264 25.0086 17.3493 25.3675C20.1527 25.977 23.6397 25.3504 26.244 23.5393C26.244 23.5393 25.3871 25.0055 23.142 25.6753C23.6561 26.345 24.2732 27.1233 24.2732 27.1233C28.0604 26.9968 29.5 24.3902 29.5 24.4083C29.5 18.6339 27.0322 13.9456 27.0322 13.9456ZM16.3037 22.725C15.3439 22.725 14.5556 21.838 14.5556 20.7337C14.6257 18.0894 17.9992 18.0976 18.0519 20.7337C18.0517 21.838 17.2805 22.725 16.3037 22.725ZM22.5591 22.725C21.5993 22.725 20.8111 21.838 20.8111 20.7337C20.8881 18.0945 24.2195 18.0934 24.3073 20.7337C24.3071 21.838 23.5359 22.725 22.5591 22.725Z"
    //         fill="white"
    //       />
    //     </svg>
    //   ),
    //   link: "https://discord.com/",
    // },
    // // telegram
    // {
    //   icon: (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       width="45"
    //       height="45"
    //       viewBox="0 0 39 39"
    //       fill="none"
    //     >
    //       <path
    //         d="M38.5 19.0995C38.5 29.6425 29.988 38.1991 19.5 38.1991C9.012 38.1991 0.5 29.6425 0.5 19.0995C0.5 8.55659 9.012 0 19.5 0C29.988 0 38.5 8.55659 38.5 19.0995Z"
    //         fill="#0F1C51"
    //       />
    //       <path
    //         d="M26.2705 12.1244L9.96869 18.4495C9.95899 18.4533 9.94959 18.4578 9.94057 18.4629C9.80838 18.538 8.88584 19.1038 10.1867 19.6111L10.2 19.616L14.0818 20.8734C14.1114 20.8831 14.1427 20.8861 14.1737 20.8824C14.2046 20.8786 14.2343 20.8681 14.2607 20.8516L23.8886 14.8285C23.9122 14.8137 23.9385 14.8038 23.9659 14.7992C24.0999 14.777 24.4856 14.7354 24.2416 15.046C23.9656 15.3985 17.3894 21.3074 16.6595 21.9627C16.6174 22.0006 16.5914 22.0532 16.5867 22.1097L16.2682 25.9169C16.2682 25.9557 16.2771 25.9939 16.2944 26.0286C16.3117 26.0633 16.3368 26.0935 16.3677 26.1168C16.4116 26.1445 16.4632 26.1573 16.5149 26.1534C16.5666 26.1494 16.6157 26.1289 16.6549 26.0949L18.9613 24.0281C18.9977 23.9955 19.0442 23.9763 19.093 23.9738C19.1418 23.9713 19.19 23.9856 19.2295 24.0144L23.2533 26.9438L23.2663 26.9526C23.3637 27.0129 24.4156 27.6266 24.7194 26.256L27.4968 13.0138C27.5007 12.9718 27.5383 12.5107 27.2096 12.2382C26.8643 11.9534 26.3756 12.0972 26.3021 12.1127C26.2913 12.1158 26.2807 12.1196 26.2705 12.1244Z"
    //         fill="white"
    //       />
    //     </svg>
    //   ),
    //   link: "https://t.me/",
    // },
  ],
};
