import React from "react";

// Files Import
import { content } from "../../data";
import { Link } from "react-router-dom";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import StyledMdText from "../../common/components/styledMdText/StyledMdText";
import StyledH5Heading from "../../common/components/styledH5Heading/StyledH5Heading";

const responsive = {
  0: { items: 1 },
  768: { items: 2 },
  1024: { items: 3 },
};

const isMobile = window.innerWidth <= 1023;

const styles = {
  backgroundImage: 'url("/assets/Images/plus-symbol.png")',
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
  height: isMobile ? "260px" : "300px",
  width: isMobile ? "260px" : "300px",
};

const renderPrevButton = ({ isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      className={`flex absolute items-center justify-center bottom-[18.5%] sm:bottom-[17.5%] left-[20%] sm:left-[40%] w-[30px] h-[30px] rounded-full ${isDisabled ? "bg-[#808080]" : "bg-white"
        }`}
    >
      <img
        src="/assets/Images/next-icon.png"
        alt="Next Button"
        width={8}
        height={8}
        className="rotate-180"
      />
    </button>
  );
};

const renderNextButton = ({ isDisabled }) => {
  return (
    <button
      disabled={isDisabled}
      className={`flex absolute items-center justify-center bottom-[18.5%] sm:bottom-[17.5%] right-[20%] sm:right-[40%] w-[30px] h-[30px] rounded-full ${isDisabled ? "bg-[#808080]" : "bg-white"
        }`}
    >
      <img
        src="/assets/Images/next-icon.png"
        alt="Next Button"
        width={8}
        height={8}
      />
    </button>
  );
};

const TeamCarousel = () => (
  <div>
    <AliceCarousel
      mouseTracking
      responsive={responsive}
      renderPrevButton={renderPrevButton}
      renderNextButton={renderNextButton}
      items={content.Team.map((item, index) => (
        <div
          key={index}
          className="w-full mx-auto space-y-5 text-white item xl:w-11/12 "
          data-value="1"
        >
          <div
            className="flex items-center justify-center mx-auto "
            style={styles}
          >
            <img
              src={item.imgURL}
              alt="BlockMed Plus Sign"
              className="rounded-full w-[230px] h-[230px] md:w-[230px] md:h-[230px]"
            />
          </div>
          <div className="bg-purple-blue h-[100px] rounded-[10px] flex justify-between items-center px-7 sm:w-[70%] md:w-full mx-auto">
            <div>
              <StyledH5Heading fontColor={"text-white"} children={item.name} />
              <StyledMdText children={item.profession} />
            </div>
            <div>
              <Link
                to={item.socialLink}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src="/assets/Images/linkedin.svg"
                  alt="linkedin"
                  className="transition duration-300 transform hover:scale-105"
                />
              </Link>
            </div>
          </div>
        </div>
      ))}
      controlsStrategy="alternate"
    />
    <style>
      {`
  @media (max-width: 768px) {
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn,
    .alice-carousel__dots {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: fit-content;
      margin: 35px auto;

    }
    .alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
      width: 30px;
      height: 10px;
      border-radius: 43px;
      margin: 0px;
      background: var(--sky-blue);
      transition: all 0.2s ease;
      
  }
    .alice-carousel__dots-item:not(.__custom):not(:last-child) {
      margin-right: 10px;
}
  }

  @media (min-width: 768px) {
    .alice-carousel__prev-btn,
    .alice-carousel__next-btn,
    .alice-carousel__dots {
      display: block !important;
      padding-top:2.75rem;
    }
  }
`}
    </style>
  </div>
);

export default TeamCarousel;
