import React from "react";

/* Files Import */
import TeamSection from "../../sections/Home/TeamSection/TeamSection";
import JoinCommunity from "../../components/JoinCommunity/JoinCommunity";
import PolygonSection from "../../sections/Home/PolygonSection/PolygonSection";
import AboutUsHeroSection from "../../sections/AboutUs/AboutUsHeroSection/AboutUsHeroSection";

const AboutUsPage = () => {
  return (
    <div>
      <AboutUsHeroSection />
      <TeamSection headingText={true} />
      <PolygonSection />
      <JoinCommunity headingText={true} padding={"pt-20 pb-14"} />
    </div>
  );
};

export default AboutUsPage;
