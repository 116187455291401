import "./App.css";

// Files Import
import Router from "./router";
import Header from "./components/layout/header/Header";
import Footer from "./components/layout/footer/Footer";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

function App() {
  return (
    <div className="max-w-[1440px] mx-auto overflow-x-hidden">
      <Header />
      <Router />
      <ScrollToTop />
      <Footer />
    </div>
  );
}

export default App;
