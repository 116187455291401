import React, { useLayoutEffect, useRef } from "react";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Files Import
import Button from "../Button/Button";
import StyledLgText from "../../common/components/styledLgText/StyledLgText";
import StyledH3Heading from "../../common/components/styledH3Heading/StyledH3Heading";

const styles = {
  backgroundImage: "url('/assets/Images/community-shadow.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
};

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const JoinCommunity = ({ padding, headingText }) => {

  const container = useRef()
  const headingsSection = useRef()

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(headingsSection.current, {
        duration: 1,
        opacity: 0,
        x: -50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: container.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=40%", // Adjust the start position as needed
          // markers: true,
        },
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <div ref={container} className="relative w-full bg-black" style={styles}>
      <div ref={headingsSection}
        className={`w-[85%] mx-auto flex flex-col gap-5 ${padding} pt-32 sm:pt-52 lg:pt-[20rem]`}
      >
        {/* Heading */}
        <div className="flex flex-col items-center justify-center gap-3 text-center ">
          {!headingText && (
            <hr className="w-56 h-px border-0 rounded-md hr-gradient3 opacity-70" />
          )}

          <div>
            <StyledH3Heading fontColor={"text-white"}>
              Join The Community.
            </StyledH3Heading>
          </div>
          <div className="md:w-1/2 lg:w-1/3">
            <StyledLgText
              fontColor={"text-white"}
              children={
                "Receive updates and news from our creators and stay upto date."
              }
            />
          </div>
          <div>
            <img
              src="/assets/Images/mail-cards.png"
              alt="Mail Cards"
              className="hidden lg:flex absolute right-0 bottom-[30%]"
            />
          </div>
        </div>
        {/* Input */}
        <div className="flex justify-center">
          <div className="relative w-full md:w-[70%] lg:w-1/2 xl:w-2/5 flex flex-col sm:flex-row items-center gap-2">
            <input
              type="email"
              placeholder="Your email here"
              className="relative text-white bg-medium-gray outline-0.5 w-full h-[60px] px-4 sm:px-6 rounded-lg
              focus:outline-0 border border-medium-gray focus:border-white"
            />
            <div className="sm:absolute top-[7px] right-2 w-full sm:w-fit">
              <Button
                width={
                  "py-[22px] !px-3 sm:!px-[20px] sm:!py-4 text-lg rounded-[7px] w-full sm:w-fit"
                }
                content={"Subscribe"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinCommunity;
