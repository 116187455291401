import React, { useLayoutEffect, useRef } from "react";

//GSAP
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Files Import
import Button from "../../../components/Button/Button";
import StyledXlText from "../../../common/components/styledXlText/StyledXlText";
import StyledH2Heading from "../../../common/components/styledH2Heading/StyledH2Heading";

//GSAP plugin registration
gsap.registerPlugin(ScrollTrigger);

const PolygonSection = () => {

  const container = useRef()
  const headingsSection = useRef()

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {

      //Animation No.1
      gsap.from(headingsSection.current, {
        duration: 1,
        opacity: 0,
        x: -50, // move from left to right
        ease: "power2.out",
        scrollTrigger: {
          trigger: container.current,
          toggleActions: "play none none reverse",
          start: "top bottom-=40%", // Adjust the start position as needed
          // markers: true,
        },
      });

    });
    return () => ctx.revert(); // cleanup
  }, []);

  return (
    <div ref={container} className="w-full">
      <div className="w-[85%] mx-auto py-8 lg:py-20 flex flex-col lg:flex-row gap-4 items-center justify-between">
        {/* Heading */}
        <div className="flex flex-col justify-center gap-3 sm:flex-row lg:w-1/2">
          <div className="-mt-2 min-w-10">
            <img src="/assets/Images/faq-start.png" alt="Shining Start" />
          </div>
          <div ref={headingsSection} className="flex flex-col gap-3 xl:w-1/2">
            <div>
              <StyledH2Heading fontColor={"text-white "}>
                Secured By <span className="text-sky-blue">Polygon</span>
              </StyledH2Heading>
            </div>
            <div>
              <StyledXlText
                fontColor={"text-light-gray md:w-11/12"}
                children={
                  "BlockMed Pro is a decentralised platform powered by polygon and web3."
                }
              />
            </div>
            <div className="mt-4">
              <Button href="/contact-us" content={"Contact Us"} />
            </div>
          </div>
        </div>
        {/* Image */}
        <div className="lg:w-1/2">
          <img className="animate-pulse" src="/assets/Images/polygon.png" alt="Animated Polygon" />
        </div>
      </div>
    </div>
  );
};

export default PolygonSection;
