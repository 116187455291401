import React from 'react'
import { Link } from 'react-router-dom'
import StyledSmText from '../../common/components/styledSmText/StyledSmText'

const TermsAndConditions = () => {
    return (
        <div>
            <Link to="/terms-and-conditions" className="text-white">
                <StyledSmText className='text-xs font-normal text-center text-white py-2'>
                    Terms and Conditions
                </StyledSmText>
            </Link>
        </div>
    )
}

export default TermsAndConditions