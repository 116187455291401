import React from "react";

// Files Import
import StyledSmText from "../../common/components/styledSmText/StyledSmText";

const Copyrights = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div>
      <StyledSmText className="text-xs font-normal text-center text-white py-2">
        &#169; {currentYear} BlockMed Pro&trade;. All Rights Reserved.
      </StyledSmText>
    </div>
  );
};

export default Copyrights;
