import React from "react";

// Files Import
import StyledXlText from "../../common/components/styledXlText/StyledXlText";
import StyledH1Heading from "../../common/components/styledH1Heading/StyledH1Heading";

const styles = {
  backgroundImage: "url('assets/Images/contact-shadow.png')",
  backgroundRepeat: "no-repeat",
  backgroundSize: "contain",
};

const ContactUsSection = () => {
  return (
    <div className="w-full" style={styles}>
      <div className="w-[85%] mx-auto flex flex-col md:flex-row gap-10 justify-center md:justify-between items-center pt-36 md:pt-24">
        {/* Heading */}
        <div className="flex flex-col gap-3 text-center md:text-start">
          <div>
            <StyledH1Heading
              fontColor={"text-white"}
              children={"Contact Us"}
            />
          </div>
          <div>
            <StyledXlText
              fontColor={"text-light-gray"}
              children={"Let’s get you connected with us"}
            />
          </div>
        </div>
        {/* Telephone Image */}
        <div className="w-fit md:w-1/2 lg:w-fit">
          <img src="/assets/Images/TelePhone-image.png" alt="Telephone" />
        </div>
      </div>
    </div>
  );
};

export default ContactUsSection;
