import React from "react";

// Files Import
import FaqSection from "../../sections/FaqSection/FaqSection";

const FaqPage = () => {
  return (
    <div>
      <FaqSection />
    </div>
  );
};

export default FaqPage;
